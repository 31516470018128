<template>
  <div class="login-wrap">
    <div class="wrap-bottom">
      <div class="login"><img src="../../../public/images/login/login.png" alt=""></div>
      <el-carousel height="100vh" :interval="3000" always="never"   class="bigBox">
        <el-carousel-item v-for="item in bannerArr" :key="item.url"  >
          <img :src="item.url" alt="" style="width:100%;height:100%;object-fit: cover;">
        </el-carousel-item>
      </el-carousel>
      <div class="modal"></div>
    </div>
    <div class="login-page">
      <!-- <img class="logo-imge" src="../../../public/images/login/4.png" alt=""> -->
      <div class="login-box" v-loading="loading">
        <div style="height:100%">
          <img style="height:100%" src="../../../public/images/login/5.png" alt="">
        </div>
        <transition name="el-zoom-in-center">
          <div class="right-box" v-if="showBox == 0">
            <div class="login_present">{{fastLoginLoading ? '快捷登陆中...' : '账号密码登录' }}</div>
            <el-form
                style="width: 300px; margin: auto;"
                :model="form"
                :rules="formRulesLogin"
                v-loading="fastLoginLoading"
                ref="formNameLogin">
              <el-form-item prop="account">
                <el-input  class="account_login" prefix-icon="el-icon-user-solid" type="text" placeholder="请输入用户名" v-model="form.account" @keyup.enter.native="submitForm('formNameLogin')"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input  class="account_login" prefix-icon="el-icon-lock" type="password" placeholder="请输入密码" v-model="form.password" @keyup.enter.native="submitForm('formNameLogin')"></el-input>
              </el-form-item>
              <div class="login_flex_el">
                <div class="remember_logo" >
                  <el-checkbox class="operating-btn" v-model="isRemember" @click="isRemember = !isRemember">记住密码</el-checkbox>
                </div>
                <!-- <div v-if="$store.state.isTest">
                  <el-link class="disabled-double-click-select" type="danger" @click="pushTestData()" icon="el-icon-warning">填充测试数据</el-link>
                </div> -->
                <div>
                  <div class="operating-btn" type="danger" @click="showBox = 1">忘记密码</div>
                </div>
              </div>
              <el-form-item>
                <div class="login_bnt_submit" @click="submitForm('formNameLogin')">登 录</div>
              </el-form-item>
            </el-form>
          </div>
        </transition>
        <transition name="el-zoom-in-center">
          <div class="right-box" v-if="showBox == 1">
            <div class="login_present">忘记密码</div>
            <el-form
                style="width: 300px; margin: auto;"
                :model="forgetPasswordForm"
                :rules="formRulesLogin"
                ref="forgetPasswordForm">
              <el-form-item prop="phone">
                <el-input  class="account_login" type="text" placeholder="请输入手机号" maxlength="11" v-model="forgetPasswordForm.phone">
                  <template slot="prepend">中国+86</template>
                </el-input>
              </el-form-item>
              <el-form-item prop="phoneCode">
                <el-input  class="account_login"  placeholder="请输入验证码" maxlength="6" v-model="forgetPasswordForm.phoneCode">
                  <el-button slot="append" v-if="timeOutNum">{{timeOutNum}}秒后获取</el-button>
                  <el-button slot="append" style="background:#fff" v-else icon="el-icon-s-promotion" @click="getPhoneCode('forgetPasswordForm')">获取验证码</el-button>
                </el-input>
              </el-form-item>
              <el-form-item prop="newPassword">
                <el-input  class="account_login" type="password" placeholder="请输入新密码" maxlength="12" v-model="forgetPasswordForm.newPassword" show-password>
                  <template slot="prepend">新密码</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <div class="login_bnt_submit" @click="submitForgetPasswordForm('forgetPasswordForm')">确认修改</div>
              </el-form-item>
              <div v-if="$store.state.isTest">
                  <div class="operating-btn" style="text-align: right;"  @click="showBox = 0" >点错了？返回登录</div>
              </div>
            </el-form>
          </div>
        </transition>
      </div>
    </div>
    <verify-slide :clickShow.sync="showCaptcha" :is-popup="true" @success="captchaSuccess" @close="captchaClose"></verify-slide>
  </div>
</template>

<script>
  var CryptoJS = require("crypto-js");
  import MD5 from 'crypto-js/md5';
import { account, required } from '../../assets/js/formRules';
import VerifySlide from "../../components/captcha/VerifySlide";
import { URL, URL_AUTH } from '../../config';
  export default {
    name: 'login',
    components:{
      VerifySlide
    },
    data(){
      return {
        bannerArr:[
          {
            url: require('../../../public/images/login/1.png'),
          },
          {
            url: require('../../../public/images/login/2.png'),
          },
          {
            url: require('../../../public/images/login/3.png'),
          }
        ],
        loading:false, // 加载
        showCaptcha:false, // 滑动校验窗口
        /*src */
        remember:'',
        isRemember: false, // 是否记住密码
        // 登录表单
        form:{
          account:'',
          password:'',
          code:'',
        },
        // 找回密码表单
        forgetPasswordForm: {
          phone: '',
          phoneCode:'',
          newPassword: '',
          code:'',
        },
        showBox: 0,
        // 表单验证规则
        formRulesLogin: {
          account,
          password:required,
          code: required,
          phone: required,
          phoneCode:required,
          newPassword:required
        },
        // 随机验证码
        loginCodeData:{
          imgBase64:'',
          randomCode_:'',
        },
        timeOutNum: 0, // 发送验证码倒计时
        fastLoginLoading:false, //快捷登录
      }
    },
    created () {
      if (localStorage.getItem('remember_info')) {
        this.isRemember = true;
        var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('remember_info'), 'login account');
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        let info = JSON.parse(originalText);
        this.form.account = info.account;
        this.form.password = info.password;
      }
      this.canFastLogin(this.$route.query)
      // this.getLoginCode()
    },
    mounted() {
    },
    methods:{
      // 快捷登录
      canFastLogin({token,siteId}){
        this.$store.commit('setToken', '');
        if(siteId && token){
          let params = {token,siteId}
          this.fastLoginLoading = true
          this.axios.post(URL_AUTH.getSupplierToken,this.qs.stringify(params)).then(res=>{
            if(res.data.token){
              this.$store.commit('setToken',res.data.token);
              setTimeout(() => {
                this.$router.push({path:'/index'});
              }, 1500)
            }
          }).catch(() => {
            this.$router.push(this.$route.path)
            this.fastLoginLoading = false
          })
        }
      },
      // 验证码验证成功回调
      captchaSuccess(data){
        this.showCaptcha = false;
        switch (this.showBox) {
          case 1:
            this.sendGetPhoneCode(data);
            break;
          default:
            this.login(data);
        }
      },
      captchaClose(){
        this.showCaptcha = false;
      },
      /**
       * 登录
       */
      login(slideData){
        console.log('登录', slideData)
        this.loading = true;
        let data = {
          account:this.form.account,
          password:MD5(this.form.password).toString(),
          captcha:this.form.code,
          randomCode_:this.loginCodeData.randomCode_,
        };
        Object.assign(data,slideData);
        this.axios.post(URL_AUTH.loginSupplier,this.qs.stringify(data)).then(res=>{
          if(res.data.token){
            this.$store.commit('setToken',res.data.token);
             if (this.isRemember) {
              let account = {
                account: this.form.account,
                password: this.form.password
              }
              var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(account), 'login account').toString()
              console.log(ciphertext)
              localStorage.setItem('remember_info', ciphertext);
            } else {
              localStorage.removeItem('remember_info');
            }
            let query = this.$route.query;
            this.$router.replace({
              name: query.originName ? query.originName : 'index',
            });
          }
        }).catch(()=>{
        }).finally(()=>{
          setTimeout(() => { this.loading = false },5e2);
        });
      },
      // 填充测试数据
      pushTestData(){
        this.form.account = 'test';
        this.form.password = '123456';
      },
      // 提交登录表单
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.showCaptcha = true;
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 提交忘记密码表单
      submitForgetPasswordForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('忘记密码表单通过校验')
            this.forgetPassword();
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 修改密码
      forgetPassword() {
        this.loading = true;
        let data = {
          phone:this.forgetPasswordForm.phone,
          phoneCode:this.forgetPasswordForm.phoneCode,
          newPassword:MD5(this.forgetPasswordForm.newPassword).toString(),
        };
        this.axios.post(URL.supplierBasicMessage.forgetPassword,this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.$message({
              message: '恭喜你，修改密码已成功,自动跳转登入页!~',
              type: 'success'
            });
            this.showBox = 0;
            this.form.account = this.forgetPasswordForm.phone;
            this.forgetPasswordForm.phone = "";
            this.forgetPasswordForm.phoneCode = "";
            this.forgetPasswordForm.newPassword = "";
          }
        }).catch(()=>{
        }).finally(()=>{
          setTimeout(() => { this.loading = false },5e2);
        });
      },
      /*点击获取验证码 */
      getPhoneCode(formName) {
        let ok = true;
        this.$refs[formName].validateField(["phone"],
          (errorMessage) => {
            if (errorMessage) {
              ok = false;
            }
          }
        );
        if (ok) {
          this.showCaptcha = true;
        }
      },
      // 调用获取验证码接口
      sendGetPhoneCode(slideData){
        console.log('获取验证码')
        this.loading = true;
        let data = {
          phone: this.forgetPasswordForm.phone,
          captcha: this.forgetPasswordForm.code,
          randomCode_: this.loginCodeData.randomCode_,
        };
        Object.assign(data,slideData);
        return new Promise(() => {
          let url = URL.supplierBasicMessage.getForgetPhoneCode;
          this.axios
            .get(url, { params: data })
            .then((res) => {
              if (res.data.flag) {
                this.codeOutTime();
              } else if (res.data.nextSecond) {
                this.codeOutTime(parseInt(res.data.nextSecond));
                this.$message.error(
                  "请在" + res.data.nextSecond + "秒后再获取"
                );
              }
            })
            .catch((res) => {
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false;
              }, 5e2);
            });
        });
      },
       // 倒计时
      codeOutTime(time = 60) {
        //倒计时
        if (time <= 0) time = 60;
        clearInterval(this.codeTimeOut);
        this.codeTimeOut = setInterval(() => {
          time -= 1;
          this.timeOutNum = time;
          if (time <= 0) clearInterval(this.codeTimeOut);
        }, 980);
      },
    },
    computed:{
    },
  }
</script>

<style lang="less" scoped>
  @import (reference) "../../assets/css/data";
  .login{
    position: fixed;
    left: 101px;
    top: 54px;
    z-index: 20;
    img {
      width: 103px;
      height: 104px;
    }
  }
  .wrap-bottom{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
  }
  .login-wrap{
    min-height: 650px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: url('../../../public/images/login/bg_1.png') no-repeat center;
    // background-size: 100% 100%;
  }
  @page-bg-height:210px;
  .login-page{
    width:884px;
    height: 580px;
    background-size: 100% 100%;
    position: relative;
    z-index: 999;
    background: #fff;
    border-radius: 28px;
  }
  .logo-imge {
    position: fixed;
    top: 70px;
    left: 70px;
    width: 98px;
    height: 101px;
  }
  .login-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .login_title {
      font-size: 30px;
      font-family: 'Source Han Sans CN';
      font-weight: bold;
      color: #575757;
      line-height: 1.1;
      span {
        color: #FF0A0A;
      }
    }
    .right-box {
      position: absolute;
      right: 66px;
      top: 0px;
    };
    .el-form-item {
      margin-bottom: 35px;
    }
    .el-icon-user-solid:before {
      color: #07A675;
    }
    .operating-btn {
      color: #999;
      font-size: 12px;
      cursor: pointer;
    }
    .login_present {
      font-size: 24px;
      font-family: 'Source Han Sans CN';
      font-weight: 400;
      color: #07A675;
      margin-top: 104px;
      text-align: center;
      line-height: 1.2;
      margin-bottom: 65px;
    }
    .login_flex_el {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      width: 285px;
      box-sizing: border-box;
    }
    .login_bnt_submit {
      display: inline-block;
      width: 300px;
      height: 43px;
      line-height: 43px;
      background: linear-gradient(90deg, #07A675, #2AB16F);
      border-radius: 21px;
      text-align: center;
      font-size: 18px;
      color: white;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    /deep/ .el-input-group__prepend {
      background: #fff;
    }
    /deep/ .el-checkbox__label {
      font-size: 12px !important;
      color: #999;
    }
    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #07A675;
      font-size: 12px !important;
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #07A675;
      border-color: #07A675;
    }
    /deep/ .el-icon-user-solid:before, /deep/ .el-icon-lock:before {
      color: #07A675;
    }
    .remember_logo {
      display: inline-block;
      cursor: pointer;
      img {
        width: 20.5px;
        height: 20.5px;
        vertical-align: middle;
        margin-right: 6.5px;
      }
      span {
        vertical-align: middle;
        margin-right: 6.5px;
        font-size: 13px;
        color: #999999;
      }
      .unselected {
        display: inline-block;
        width: 18.5px;
        height: 18.5px;
        border: 1px solid #F5F5F5;
      }
    }
    .account_login {
      width: 300px;
      // height: 42px;
      border: 1px solid #07A675 ;
      border-radius: 4px;

      // outline: none;
      // box-sizing: border-box;
      // font-size: 16px;
    }
    .account_login::-webkit-input-placeholder {
      color: #D4D4D4;
      font-weight: 500;
      font-size: 16px;
    }
    .account_login:-moz-placeholder {
      color: #D4D4D4;
      font-weight: 500;
      font-size: 16px;
    }
    .account_login:-ms-input-placeholder {
      color: #D4D4D4;
      font-weight: 500;
      font-size: 16px;
    }

  }
  .login-title{
    color: @color-success;
    text-align: center;
    font-size: 28px;
    margin: 30px auto;
  }
  .code-input-box{
    display: flex;
    width: 100%;
    .el-input{
      width: auto;
      flex: 1;
    }
    .code{
      margin-left: 20px;
    }
  }
  .code{
    min-width: 80px;
    height: 40px;
    width: auto;
    border-radius: 6px;
    background-color: #f2f2f2;
    overflow: hidden;
  }
  .login-box /deep/ .el-input__inner{
      border: 0;
      border-bottom: 1px solid #DCDFE6;
  }
</style>
